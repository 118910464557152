<template>
<el-checkbox v-model="checked" @change="emitChange" :disabled="disabled">加班申请是否遵循此规则</el-checkbox>
</template>

<script>
export default {
  enName: '加班调整',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    emitChange() {
      this.$emit('input', this.checked);
    },
  },
};
</script>

<style lang="less" scoped>
.gray{
 color: gray;
}
</style>
