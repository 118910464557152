<template>
  <div>
    <div>
      <div>
        <span class="margin-right">必须打卡的日期</span>
        <el-button type="text" icon="el-icon-circle-plus-outline"
                   @click="btnSpecialTimeAdd('sfaWorkSignSpecialMustReqVos')"
                   :style="{display:disabled ? 'none' : 'inline-block'}">添加
        </el-button>
      </div>
      <el-row class="margin-top-8" type="flex" align="middle"
              v-for="(item, index) in sfaWorkSignSpecialMustReqVos" :key="index">
        <span>选择日期：</span>
        <el-date-picker v-model="item.wssDate" @change="emitChange"
                        value-format="yyyy-MM-dd" clearable
                        :disabled="disabled">
        </el-date-picker>
        <i class="margin-left-8 el-icon-delete icon-delete"
           @click="btnSpecialTimeRemove('sfaWorkSignSpecialMustReqVos', index)"
           :style="{display:disabled ? 'none' : 'inline-block'}">删除
        </i>
      </el-row>
    </div>

    <div class="margin-top-16">
      <div>
        <span class="margin-right">不必须打卡的日期</span>
        <el-button type="text" icon="el-icon-circle-plus-outline"
                   @click="btnSpecialTimeAdd('sfaWorkSignSpecialNotReqVos')"
                   :style="{display:disabled ? 'none' : 'inline-block'}">添加
        </el-button>
      </div>
      <el-row class="margin-top-8" type="flex" align="middle"
              v-for="(item, index) in sfaWorkSignSpecialNotReqVos" :key="index">
        <span>选择日期：</span>
        <el-date-picker v-model="item.wssDate" @change="emitChange"
                        value-format="yyyy-MM-dd"
                        clearable
                        :disabled="disabled">
        </el-date-picker>
        <i class="margin-left-8 el-icon-delete icon-delete"
           @click="btnSpecialTimeRemove('sfaWorkSignSpecialNotReqVos', index)"
           :style="{display:disabled ? 'none' : 'inline-block'}">删除
        </i>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  enName: '特殊日期组件',

  props: {
    value: {
      type: Object,
      default() {
        return {
          sfaWorkSignSpecialMustReqVos: [], // 必须打卡的时间
          sfaWorkSignSpecialNotReqVos: [], // 不必须打卡的时间
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.sfaWorkSignSpecialMustReqVos = val.sfaWorkSignSpecialMustReqVos;
          this.sfaWorkSignSpecialNotReqVos = val.sfaWorkSignSpecialNotReqVos;
        }
      },
    },
  },
  data() {
    return {
      sfaWorkSignSpecialMustReqVos: [], // 必须打卡的日期
      sfaWorkSignSpecialNotReqVos: [], // 不用打卡的日期
    };
  },
  created() {
    console.log('查看特殊日期======:create方法', this.disabled);
  },
  methods: {
    /** @desc 增加特殊日期 */
    btnSpecialTimeAdd(type) {
      console.log('打印增加=======', type);
      const item = {
        wssDate: '',
        wssType: type === 'sfaWorkSignSpecialMustReqVos' ? 1 : 2,
      };
      this[type].push(item);
      this.emitChange();
    },
    /** @desc 减少特殊日期 */
    btnSpecialTimeRemove(type, index) {
      this[type].splice(index, 1);
      this.emitChange();
    },
    emitChange() {
      this.$emit('input', {
        sfaWorkSignSpecialMustReqVos: this.sfaWorkSignSpecialMustReqVos,
        sfaWorkSignSpecialNotReqVos: this.sfaWorkSignSpecialNotReqVos,
      });
    },
  },
};
</script>

<style lang="less" scope>
.margin-right{
  margin-right: 6px;
}
.margin-top-16{
  margin-top: 16px;
}
.margin-left-8{
  margin-left: 8px;
}
.margin-top-8{
  margin-top: 8px;
}
.icon-delete{
  color: red;
}
</style>
