var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("span", [_vm._v("（可添加多个打卡时间段）")]),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.btnAdd },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.timeList, function (item, index) {
        return _c("div", { key: index, staticClass: "clock-item" }, [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", align: "middle" } },
                [
                  _c("el-time-picker", {
                    attrs: {
                      placeholder: "请选择上班时间",
                      "value-format": "HH:mm:ss",
                      clearable: "",
                      disabled: _vm.disabled,
                    },
                    on: { change: _vm.timePickerChange },
                    model: {
                      value: item.gotoTime,
                      callback: function ($$v) {
                        _vm.$set(item, "gotoTime", $$v)
                      },
                      expression: "item.gotoTime",
                    },
                  }),
                  _c("span", [_vm._v("---")]),
                  _c("el-time-picker", {
                    attrs: {
                      placeholder: "请选择下班时间",
                      "value-format": "HH:mm:ss",
                      clearable: "",
                      disabled: _vm.disabled,
                    },
                    on: { change: _vm.timePickerChange },
                    model: {
                      value: item.gooffTime,
                      callback: function ($$v) {
                        _vm.$set(item, "gooffTime", $$v)
                      },
                      expression: "item.gooffTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "margin-top", attrs: { type: "flex" } },
                [
                  _c("span", { staticClass: "margin-right" }, [
                    _vm._v("打卡时间限制"),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "middle" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                              "value-format": "HH:mm:ss",
                              disabled: _vm.disabled,
                            },
                            on: { change: _vm.timePickerChange },
                            model: {
                              value: item.gotoLimitRanage,
                              callback: function ($$v) {
                                _vm.$set(item, "gotoLimitRanage", $$v)
                              },
                              expression: "item.gotoLimitRanage",
                            },
                          }),
                          _c("span", { staticClass: "margin-left" }, [
                            _vm._v("可打上班卡"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "margin-top",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                              "value-format": "HH:mm:ss",
                              disabled: _vm.disabled,
                            },
                            on: { change: _vm.timePickerChange },
                            model: {
                              value: item.gooffLimitRanage,
                              callback: function ($$v) {
                                _vm.$set(item, "gooffLimitRanage", $$v)
                              },
                              expression: "item.gooffLimitRanage",
                            },
                          }),
                          _c("span", { staticClass: "margin-left" }, [
                            _vm._v("可打下班卡"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          !_vm.disabled
            ? _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.btnRemove(index)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-delete icon-delete" }, [
                    _vm._v("删除"),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }