var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { style: { display: _vm.disabled ? "none" : "block" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-plus" },
              on: { click: _vm.btnAddOrg },
            },
            [_vm._v("添加组织")]
          ),
          _c(
            "i",
            {
              staticClass: "el-icon-delete btn-delete",
              on: { click: _vm.btnRemoveAll },
            },
            [_vm._v("清空")]
          ),
        ],
        1
      ),
      _c(
        "vxe-table",
        { attrs: { data: _vm.sfaWorkSignPersonnelReqVo } },
        [
          _c("vxe-table-column", {
            attrs: { title: "序号", width: "70px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(_vm._s(rowIndex + 1))]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { title: "组织编码", field: "wspCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "组织名称", field: "wspName" },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: { title: "操作", field: "oper", width: "100px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.btnTableRemove(rowIndex)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1509893719
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            modalConfig: {
              type: "Modal",
              visible: _vm.dialog.visible,
              title: "选择组织",
              width: "800px",
            },
            destroyOnClose: "",
          },
          on: {
            cancel: _vm.dialog_btnClose,
            ok: _vm.dialog_btnConfirm,
            onClose: _vm.dialog_btnClose,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "search" },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入组织名称", clearable: "" },
                    model: {
                      value: _vm.params.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "name", $$v)
                      },
                      expression: "params.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", icon: "el-icon-refresh" },
                      on: { click: _vm.handleResetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              attrs: {
                border: "",
                "tree-config": { children: "children" },
                data: _vm.dialog.treeData,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("vxe-checkbox", {
                          attrs: { disabled: row.disabled },
                          on: {
                            change: function ($event) {
                              return _vm.dialog_rowCheckChange(row)
                            },
                          },
                          model: {
                            value: row.checked,
                            callback: function ($$v) {
                              _vm.$set(row, "checked", $$v)
                            },
                            expression: "row.checked",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: { "tree-node": "", field: "name", title: "组织名称" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }