var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-checkbox",
    {
      attrs: { disabled: _vm.disabled },
      on: { change: _vm.emitChange },
      model: {
        value: _vm.checked,
        callback: function ($$v) {
          _vm.checked = $$v
        },
        expression: "checked",
      },
    },
    [
      _vm._v("同步中国节假日"),
      _c("span", { staticClass: "gray" }, [_vm._v("节假日期间不用打卡")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }