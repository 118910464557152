<template>
<el-checkbox v-model="checked" @change="emitChange" :disabled="disabled">员工打卡时必须拍照</el-checkbox>
</template>

<script>
export default {
  enName: '拍照打卡',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val;
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('input', this.checked);
    },
  },
  created() {
    console.log('拍照打卡===========create方法', this.disabled);
  },
  data() {
    return {
      checked: false,
    };
  },

};
</script>
