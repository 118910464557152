<script>
import Form, { formCreate } from '../../../../../../components/form';

export default {
  extends: Form,
  async created() {
    const that = this;
    const rule = await this.getFormRule('sfa_attendance_rule_map_form');
    this.rule = rule.map((rowData) => {
      let item = rowData;
      if (item.field === 'placeName') {
        item = Object.assign(item, {}, {
          props: { disabled: true },
        });
      } else if (item.field === 'placeRange') {
        item.on = {
          ...item.on,
          change: (val) => {
            this.$emit('rangeChange', val);
          },
        };
      }
      return item;
    });
    this.reload(this.rule);
  },
};
</script>
