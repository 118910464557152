<template>
<el-checkbox v-model="checked" @change="emitChange" :disabled="disabled">同步中国节假日<span class="gray">节假日期间不用打卡</span></el-checkbox>
</template>

<script>
export default {
  enName: '节假日',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    emitChange() {
      this.$emit('input', this.checked);
    },
  },
};
</script>

<style lang="less" scoped>
.gray{
 color: gray;
}
</style>
