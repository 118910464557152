<script>
import { Message } from 'element-ui';
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

// import RuleType from './components/rule_type.vue';
import ClockRange from './components/clock_range.vue';
import ClockInTime from './components/clock_in_time.vue';
import SpecialTime from './components/special_time.vue';
import WeekDayAdjust from './components/week_day_adjust.vue';
import Holiday from './components/holiday.vue';
import PhotoClock from './components/photo_clock_time.vue';
import ClockRule from './components/clock_rule.vue';
// import ClockPositionLevel from './components/clocl_position_level.vue';

// formCreate.component('sfaAttendanceRuleRuleType', RuleType);
formCreate.component('sfaAttendanceRuleRuleClockRange', ClockRange);
formCreate.component('sfaAttendanceRuleRuleClockInTime', ClockInTime);
formCreate.component('sfaAttendanceRuleRuleSpecialTime', SpecialTime);

formCreate.component('sfaAttendanceRuleRuleWeekDayAdjust', WeekDayAdjust);
formCreate.component('sfaAttendanceRuleRuleHoliday', Holiday);
formCreate.component('sfaAttendanceRuleRulePhotoClock', PhotoClock);
formCreate.component('sfaAttendanceRuleRuleClockRule', ClockRule);
// formCreate.component('sfaAttendanceRuleRuleClockPositionLevel', ClockPositionLevel);

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: false,
      },
      timer: null,
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    getDetail() {
      if (!this.formConfig.rowData.id) return;
      request.post('/sfa/sfaworksignrule/query', { id: this.formConfig.rowData.id }).then((res) => {
        if (!res.success) return;
        const resultData = res.result;

        if (resultData.ruleType && resultData.ruleType === 'FREE_TIME') {
          this.hiddenFields(true, ['clockInTime']);
        } else {
          this.hiddenFields(false, ['clockInTime']);
        }
        this.setValue({
          // 规则名称
          ruleName: resultData.ruleName,
          // 规则类型
          ruleType: resultData.ruleType,
          // 打卡范围
          clockRange: {
            sfaWorkSignPersonnelReqVo: resultData.sfaWorkSignPersonnelRespVo ? resultData.sfaWorkSignPersonnelRespVo : [],
          },
          // 打卡范围-职位级别
          clockPositionLevel: resultData.sfaWorkSignPersonnelPosRespVo || [],
          // 打卡规则
          clockRule: {
            placeList: resultData.sfaWorkSignPlaceRespVos,
            electronFence: resultData.electronFence,
          },
          // 工作日
          workingDay: resultData.workingDay.length > 0 ? resultData.workingDay.split(',') : [],
          // 打卡时间
          clockInTime: resultData.sfaWorkSignTimeRespVos ? resultData.sfaWorkSignTimeRespVos : [],
          // 工作日调整
          nonWorkingDaySignAstrict: resultData.nonWorkingDaySignAstrict === 'Y',
          // 特殊日期
          specialTime: {
            sfaWorkSignSpecialMustReqVos: resultData.sfaWorkSignSpecialMustRespVos ? resultData.sfaWorkSignSpecialMustRespVos : [],
            sfaWorkSignSpecialNotReqVos: resultData.sfaWorkSignSpecialNotRespVos ? resultData.sfaWorkSignSpecialNotRespVos : [],
          },
          // 下班
          gooffWorkSignAstrict: resultData.gooffWorkSignAstrict,
          // 节假日
          holidayWhether: resultData.holidayWhether === 'Y',
          // 拍照打卡
          wsrPhotograph: resultData.wsrPhotograph === 'Y',

        });
      });
    },
    /** @override 覆盖提交事件 */
    submit() {
      const emitData = this.getFormData();

      // 1.验证不通过
      if (!emitData) return;
      // 2.保存请求时的请求数据的字段是请求的字段,emitData 里的字段是Form配置的字段
      const requestParam = {
        // 规则名称
        ruleName: emitData.ruleName,
        // 规则类型
        ruleType: emitData.ruleType,
        // 打卡范围List
        sfaWorkSignPersonnelReqVo: emitData.clockRange.sfaWorkSignPersonnelReqVo,
        // 打卡范围-职位级别
        sfaWorkSignPersonnelPosReqVo: emitData.clockPositionLevel || [],
        // 工作日
        workingDay: emitData.workingDay.join(','),
        // 打卡规则
        sfaWorkSignPlaceReqVos: emitData.clockRule ? emitData.clockRule.placeList : [],
        // 电子围栏
        electronFence: emitData.clockRule ? emitData.clockRule.electronFence : '',
        // 打卡时间
        sfaWorkSignTimeReqVos: emitData.clockInTime ? emitData.clockInTime : [],
        // 工作日调整
        nonWorkingDaySignAstrict: emitData.nonWorkingDaySignAstrict ? 'Y' : 'N',
        // 特殊日期必须打卡List
        sfaWorkSignSpecialMustReqVos: emitData.specialTime.sfaWorkSignSpecialMustReqVos,
        // 特殊日期不用打卡List
        sfaWorkSignSpecialNotReqVos: emitData.specialTime.sfaWorkSignSpecialNotReqVos,
        // 下班
        gooffWorkSignAstrict: emitData.gooffWorkSignAstrict,
        // 节假日
        holidayWhether: emitData.holidayWhether ? 'Y' : 'N',
        // 拍照打卡
        wsrPhotograph: emitData.wsrPhotograph ? 'Y' : 'N',
      };

      let requestUrl = '/sfa/sfaworksignrule/save';

      // 3.编辑的时候
      if (this.formConfig.rowData.id) {
        requestUrl = '/sfa/sfaworksignrule/update';
        requestParam.id = this.formConfig.rowData.id;
        requestParam.sfaWorkSignPersonnelReqVo = requestParam.sfaWorkSignPersonnelReqVo.map((v) => ({ ...v, ruleCode: this.formConfig.rowData.ruleCode }));
      }
      // console.log(JSON.stringify(requestParam));

      request.post(requestUrl, requestParam).then(() => {
        Message.success('操作成功!');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
  /** @desc
   * 重新赋值rule,反正感觉怪怪的有问题
  */
  async created() {
    const rule = await this.getFormRule('sfa_attendance_rule_add');
    const that = this;
    rule.forEach((v) => {
      const item = v;
      /** @desc 规则名称 */
      if (item.field === 'ruleName') {
        Object.assign(item, {}, {
          value: '',
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 规则类型 */
      if (item.field === 'ruleType') {
        item.props = {
          ...item.props,
          disabled: this.formConfig.disabled,
        };
        item.on = {
          change(val) {
            if (val === 'FREE_TIME') {
              that.hiddenFields(true, ['clockInTime']);
            } else {
              that.hiddenFields(false, ['clockInTime']);
            }
          },
        };
      }
      /** @desc 打卡范围 */
      if (item.field === 'clockRange') {
        Object.assign(item, {}, {
          value: {
            sfaWorkSignPersonnelReqVo: [],
          },
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 工作日 */
      if (item.field === 'workingDay') {
        Object.assign(item, {}, {
          // value: [],
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 打卡时间 */
      if (item.field === 'clockInTime') {
        Object.assign(item, {}, {
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 工作日调整 */
      if (item.field === 'nonWorkingDaySignAstrict') {
        Object.assign(item, {}, {
          value: false,
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 特殊日期 */
      if (item.field === 'specialTime') {
        Object.assign(item, {}, {
          value: {
            sfaWorkSignSpecialMustReqVos: [],
            sfaWorkSignSpecialNotReqVos: [],
          },
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 下班 */
      if (item.field === 'gooffWorkSignAstrict') {
        Object.assign(item, {}, {
          value: false,
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 节假日 */
      if (item.field === 'holidayWhether') {
        Object.assign(item, {}, {
          value: false,
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 拍照打卡 */
      if (item.field === 'wsrPhotograph') {
        Object.assign(item, {}, {
          value: false,
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      if (item.field === 'clockRule') {
        Object.assign(item, {}, {
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
      /** @desc 职位级别 */
      if (item.field === 'clockPositionLevel') {
        Object.assign(item, {}, {
          props: {
            disabled: this.formConfig.disabled,
          },
        });
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    this.buttons.submit = !this.formConfig.disabled;
    this.timer = setTimeout(() => {
      this.getDetail();
    }, 300);
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  },
};
</script>
