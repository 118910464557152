<script>
import TablePage from '../../../../../components/table_page';
import FormAdd from './form.vue';
import request from '../../../../../utils/request';

export default {
  name: 'proportion',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      params: {
        year: '',
        month: '',
      },
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
        id: '',
      },
    };
  },
  components: {
    FormAdd,
  },
  methods: {
    /** @override 复写行按钮组操作 */
    modalClick({ val, row }) {
      if (val.code === 'edit') return this.btnEdit(row);
      // if (val.buttonCode === 'log') return this.btnLog();
    },
    /** @override 复写特殊的查看详情按钮 */
    cellClick({ column, row }) {
      if (column.property === 'ruleCode') {
        return this.btnCheck(row);
      }
    },
    /**
     * @override 覆盖头部按钮操作事件
     * @desc 重写 删除/启用/禁用按钮的方法,是根据接口配置来的
    */
    buttonClick({ val }) {
      // 新增
      if (val.buttonCode === 'add') return this.btnAdd();
      // 删除,启用,禁用,手动刷新
      if (val.buttonCode === 'delete' || val.buttonCode === 'enable' || val.buttonCode === 'disable' || val.buttonCode === 'manually_refresh') {
        this.btnDeleteOrEnableOrDisableOrRefresh(val);
      }
    },
    /** @desc 新增事件 */
    btnAdd() {
      this.formName = 'FormAdd';
      this.formConfig.rowData = {};
      this.modalConfig.title = '新增';
      this.openFull();
    },
    /** @desc 编辑事件 */
    btnEdit(row) {
      this.formName = 'FormAdd';
      this.formConfig.rowData = row;
      this.formConfig.disabled = false;
      this.modalConfig.title = '编辑';
      this.openFull();
    },
    /** @desc 查看事件 */
    btnCheck(row) {
      this.formName = 'FormAdd';
      this.formConfig.rowData = row;
      this.formConfig.disabled = true;
      this.modalConfig.title = '查看';
      this.openFull();
    },
    /** @desc 批量启用,批量禁用,批量删除 */
    btnDeleteOrEnableOrDisableOrRefresh(val) {
      if (this.selectRow.length === 0) return this.$message('请至少选择一条数据！');
      this.$confirm(val.ask, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const msgObj = {
          delete: '批量删除成功！',
          enable: '批量启用成功！',
          disable: '批量禁用成功！',
          manually_refresh: '手动刷新成功!',
        };
        let requestParams = null;

        // 1.除了手动刷新需要传一个对象包一个ids，其他的直接传id的数组
        if (val.buttonCode !== 'manually_refresh') {
          requestParams = this.selectRow.map((v) => v.id);
        } else {
          requestParams = {
            ids: this.selectRow.map((v) => v.id),
          };
        }
        request.post(val.apiUrl, requestParams).then((res) => {
          if (!res.success) return;
          this.$message.success(msgObj[val.buttonCode]);
          this.getList();
        });
      }).catch(() => {
        console.log('已取消confirm=======');
      });
    },
    /** @desc 日志 */
    // btnLog() {
    //   console.log('this is log button');
    // },
  },
  async mounted() {
    const columns = await this.getConfigList('sfa_center_attendance_rule');
    columns.map((rowData) => {
      if (rowData.field === 'createDate') {
        // eslint-disable-next-line no-param-reassign
        rowData.formatter = ({ row }) => `${row.createDate ? row.createDate : ''} ${row.createDateSecond ? row.createDateSecond : ''}`;
      }
      if (rowData.field === 'updateDate') {
        // eslint-disable-next-line no-param-reassign
        rowData.formatter = ({ row }) => `${row.updateDate ? row.updateDate : ''} ${row.updateDateSecond ? row.updateDateSecond : ''}`;
      }
      return { ...rowData };
    });
    this.configs.columns = columns;
  },
};
</script>
