<template>
  <div>
    <el-row :style="{display:disabled ? 'none' : 'block'}">
      <el-button type="text" icon="el-icon-plus" @click="btnAddOrg">添加组织</el-button>
      <i class="el-icon-delete btn-delete" @click="btnRemoveAll">清空</i>
    </el-row>
    <vxe-table :data="sfaWorkSignPersonnelReqVo">
      <vxe-table-column title="序号" width="70px">
        <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
      </vxe-table-column>
      <vxe-table-column title="组织编码" field="wspCode"></vxe-table-column>
      <vxe-table-column title="组织名称" field="wspName"></vxe-table-column>
      <vxe-table-column title="操作" field="oper" v-if="!disabled" width="100px">
        <template v-slot="{rowIndex}">
          <el-button type="text" @click="btnTableRemove(rowIndex)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <modal  :modalConfig="{type:'Modal',visible:dialog.visible,title:'选择组织',width:'800px'}"
            @cancel="dialog_btnClose" @ok="dialog_btnConfirm" destroyOnClose @onClose="dialog_btnClose">
      <el-row class="search">
        <el-col :span="8">
          <el-input v-model="params.name" placeholder="请输入组织名称" clearable></el-input>
        </el-col>
        <el-col :span="8" :offset="8">
          <el-button type="primary" @click="handleQuery" icon="el-icon-search">查询</el-button>
          <el-button type="default" @click="handleResetQuery" icon="el-icon-refresh">重置</el-button>
        </el-col>
      </el-row>
      <vxe-table border
                :tree-config="{ children: 'children' }"
                :data="dialog.treeData">
          <vxe-table-column width="100px">
            <template v-slot="{row}">
              <vxe-checkbox :disabled="row.disabled" v-model="row.checked" @change="dialog_rowCheckChange(row)"></vxe-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column tree-node field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import request from '../../../../../../utils/request';
import modal from '../../../../../../components/modal';

export default {
  enName: '打卡范围',
  props: {
    value: {
      type: Object,
      default() {
        return {
          sfaWorkSignPersonnelReqVo: [],
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.sfaWorkSignPersonnelReqVo = [...val.sfaWorkSignPersonnelReqVo];
        }
      },
    },
    'dialog.visible': {
      handler(val) {
        if (val) this.dialog_getList();
      },
    },
  },
  components: { modal },
  data() {
    return {
      sfaWorkSignPersonnelReqVo: [],
      /** @desc 弹窗的所有数据 */
      dialog: {
        visible: false, // 弹窗状态
        treeData: [], // 列表
      },
      params: { // 添加组织增加搜索条件
        name: '',
      },
    };
  },

  methods: {
    /** @desc 增加组织 */
    btnAddOrg() {
      this.dialog.visible = true;
    },
    /** @desc 清除所有 */
    btnRemoveAll() {
      this.sfaWorkSignPersonnelReqVo = [];
      this.emitChange();
    },
    /** @desc 删除组织 */
    btnTableRemove(index) {
      this.sfaWorkSignPersonnelReqVo.splice(index, 1);
      this.emitChange();
    },
    emitChange() {
      this.$emit('input', {
        sfaWorkSignPersonnelReqVo: this.sfaWorkSignPersonnelReqVo,
      });
    },

    /** --------------------------------以下是弹窗负责事件----------------------------------*/
    /** @desc 单选框改变事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_rowCheckChange(row) {
      function recure(arr) {
        arr.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          if (!item.disabled) {
            // eslint-disable-next-line no-param-reassign
            item.checked = row.checked;
          }
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      }
      if (row.children && Array.isArray(row.children) && row.children.length > 0) {
        const cloneChildren = JSON.parse(JSON.stringify(row)).children;
        recure(cloneChildren);
        this.$set(row, 'children', cloneChildren);
      }
    },
    /** @desc 获取选中的列表 */
    dialog_getCheckList() {
      const checkList = [];
      function recure(arr) {
        // eslint-disable-next-line array-callback-return
        arr.map((item) => {
          if (item.checked) checkList.push(item);
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      }
      recure(this.dialog.treeData);
      return checkList;
    },
    /** @desc 按钮确认事件 */
    // eslint-disable-next-line consistent-return
    dialog_btnConfirm() {
      const checkedList = this.dialog_getCheckList();
      if (checkedList.length === 0) return Message.warning('请至少选择一条组织！');
      const selection = checkedList.map((item) => ({
        ...item, wspCode: item.code, wspName: item.name, currentAble: item.parentCode ? 'N' : 'Y', codeType: 'org',
      }));
      this.dialog.visible = false;
      this.sfaWorkSignPersonnelReqVo = selection;
      this.emitChange();
    },
    /** @desc 按钮关闭事件 */
    dialog_btnClose() {
      this.dialog.treeData = [];
      this.dialog.checkRowKeys = [];
      this.dialog.visible = false;
    },
    /** @desc 获取列表 */
    async dialog_getList() {
      try {
        const treeResult = await request.post('/mdm/baseTreeController/orgTree', this.params, { headers: { functionCode: 'select-org' } });
        // const readyResult = await request.post('/sfa/sfaworksignrule/getAlreadySetOrgList');

        const treeData = treeResult ? treeResult.result : [];
        // const readyData = readyResult ? readyResult.result : [];

        // this.dialog_utilRecureData(treeData, readyData);
        this.dialog.treeData = treeData;
      } catch (error) { console.log(error); }
    },
    /** @desc 搜索列表 */
    handleQuery() {
      this.dialog_getList();
    },
    /** @desc 重置查询 */
    handleResetQuery() {
      this.params = {
        name: '',
      };
      this.dialog_getList();
    },
    /**
     * @desc 根据已绑定组织进行重设数据
     * @todo 1.根据后端请求已经绑定的组织
     * @todo 2.从已经绑定的组织中剔除本条数据绑定的数据列表【】
     * @todo 3.把已绑定的弄为disabled,把剔除的弄成选中
    */
    dialog_utilRecureData(treeData, readyData) {
      // 1.过滤出来的数据
      const filterReadyData = [];
      // eslint-disable-next-line array-callback-return
      readyData.map((vitem) => {
        const find = this.sfaWorkSignPersonnelReqVo.find((mitem) => mitem.wspCode === vitem.wspCode);
        if (!find) filterReadyData.push(vitem);
      });

      console.log('列表的数据==============', this.sfaWorkSignPersonnelReqVo);
      console.log('打印已绑定的数据=========', readyData);
      console.log('打印过滤后的数据=========', filterReadyData);

      // 2.进行操作
      const recure = (arr) => {
        arr.forEach((row) => {
          const item = row;
          const findDisabled = filterReadyData.find((v) => item.code === v.wspCode);
          const findCheck = this.sfaWorkSignPersonnelReqVo.find((v) => item.code === v.wspCode);
          item.disabled = !!findDisabled;
          item.checked = !!findCheck;
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      };
      recure(treeData);
    },
  },
};
</script>

<style lang="less" scoped>
.btn-delete{
  color:red;
  margin-left: 8px;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
.search {
  margin-bottom: 10px;
}
</style>
