<template>
  <div>
    <el-form inline>
      <!-- <el-form-item label="打卡地点">
        <span>（可添加多个打卡范围，并且设置电子围栏有效打卡范围）</span>
        <el-button v-if="!disabled" type="text" icon="el-icon-circle-plus-outline" @click="btnAdd">添加</el-button>
      </el-form-item> -->
    </el-form>
    <!-- <vxe-table :data="placeList" show-overflow>
      <vxe-table-column title="序号" width="70px">
        <template v-slot="{ rowIndex }">{{rowIndex+1}}</template>
      </vxe-table-column>
      <vxe-table-column title="地址" field="placeName"></vxe-table-column>
      <vxe-table-column title="打卡类型" field="placeSignType_name"></vxe-table-column>
      <vxe-table-column title="范围" field="placeRange_name"></vxe-table-column>
      <vxe-table-column title="操作" width="100px" v-if="!disabled">
        <template v-slot="{ rowIndex }">
          <el-button type="text" @click="btnRemove(rowIndex)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table> -->
    <el-form inline v-if="placeList.length">
      <el-form-item label="电子围栏">
        <el-radio-group v-model="electronFence" @change="radioChange" :disabled="disabled">
          <el-radio label="NONE">无电子围栏</el-radio>
          <el-radio label="OUT_SIGN_EX">允许范围外打卡，地点记录为异常</el-radio>
          <el-radio label="OUT_SIGN_OK">允许范围外打卡，地点记录为正常</el-radio>
          <el-radio label="NO_OUT_SIGN">不允许范围外打卡</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <MapByAttendance :visible.sync="visible" @submit="btnSubmit"></MapByAttendance>
  </div>
</template>

<script>
import MapByAttendance from './map_attendance.vue';
import * as utils from '../../../../utils/index';
import request from '../../../../../../utils/request';

export default {
  name: 'clock_rule',
  enName: '打卡规则',
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { MapByAttendance },
  watch: {
    value: {
      handler(curVal) {
        if (curVal) {
          this.placeList = curVal.placeList.map((item) => {
            const findType = this.libData.signTypeList.find((mitem) => mitem.dictCode === item.placeSignType);
            const findRanage = this.libData.signRangeList.find((mitem) => mitem.dictCode === item.placeRange);
            console.log('监听打卡规则=============findType', findType);
            console.log('监听打卡规则=============findRanage', findRanage);
            return {
              ...item,
              placeSignType_name: findType ? findType.dictValue : '',
              placeRange_name: findRanage ? findRanage.dictValue : '',
            };
          });
          this.electronFence = curVal.electronFence;
        }
      },
    },
  },
  data() {
    return {
      placeList: [], // 打卡地点
      electronFence: '', // 电子围栏
      visible: false,
      libData: {
        signTypeList: [], // 打卡类型
        signRangeList: [], // 打卡范围
      },
    };
  },
  methods: {
    /** @desc 获取依赖数据 */
    getLibData() {
      // todo 打卡类型
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'signPlaceCheckType', selectedCode: null }).then((res) => {
        if (!res.success) return;
        this.libData.signTypeList = res.result;
      });
      // todo 打卡范围
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'signPlaceScope', selectedCode: null }).then((res) => {
        if (!res.success) return;
        this.libData.signRangeList = res.result;
      });
    },
    /** @desc 提交按钮
     * item={placeName: '', // 打卡地点,placeRange: '', // 打卡范围,placeLatin: '', // 维度,placeLngin: '', // 精度}
    */
    btnSubmit(item) {
      // todo 不知道后端为什么要wspNo这个序号，🔨🐔巴作用都没有,也不查数据库，为了方便直接简便的UUID。
      const findType = this.libData.signTypeList.find((mitem) => mitem.dictCode === item.placeSignType);
      const findRanage = this.libData.signRangeList.find((mitem) => mitem.dictCode === item.placeRange);
      const obj = {
        ...item,
        placeSignType_name: findType ? findType.dictValue : '',
        placeRange_name: findRanage ? findRanage.dictValue : '',
        wspNo: utils.guid(),
      };
      this.placeList = this.placeList.concat([obj]);
      this.emitChange();
    },
    /** @desc 新增按钮 */
    btnAdd() {
      this.visible = true;
    },
    /** @desc 删除按钮 */
    btnRemove(index) {
      this.placeList.splice(index, 1);
      this.emitChange();
    },
    /** @desc 电子围栏改变事件 */
    radioChange() {
      this.emitChange();
    },
    /** @desc 发射数据 */
    emitChange() {
      this.$emit('input', {
        placeList: this.placeList,
        electronFence: this.electronFence,
      });
    },
  },
  created() {
    this.getLibData();
  },
};
</script>
