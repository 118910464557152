<template>
  <Modal :modalConfig="modalConfig" @onClose="btnClose">
    <div >
      <Map ref="TMap" :circleRadio="circleRadio" @addressChange="addressChange" btnText="同步拜访计划" @clickBtn="syncVisit"></Map>
    </div>
    <div class="margin-top-10">
      <FormMap ref="formMap" @rangeChange="rangeChange"></FormMap>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="btnSubmit" size="small" icon="el-icon-check">提交</el-button>
      <el-button type="danger" @click="btnClose" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../../../../../components/modal/components/modal.vue';
import Map from '../../../../../../components/tencent_map/map.vue';
import FormMap from './map_form.vue';

export default {
  components: { Modal, Map, FormMap },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.modalConfig.visible = val;
      },
    },
  },
  data() {
    return {
      modalConfig: {
        type: 'Modal',
        width: '80%',
        height: '80vh',
        visible: false,
        title: '腾讯地图',
        showFooter: true,
      },
      circleRadio: 100,
    };
  },
  methods: {
    /** @desc 地图点击按钮事件 */
    syncVisit() {
      this.$refs.formMap.setValue({
        placeName: '同步拜访计划',
        placeLatin: '30.697162679',
        placeLngin: '104.073498371',
      });
    },
    /** @desc 地图点击事件 */
    addressChange(placeInfo) {
      console.log('111122');
      this.$refs.formMap.setValue({
        placeName: placeInfo.address,
        placeLatin: placeInfo.lat,
        placeLngin: placeInfo.lng,
      });
    },
    rangeChange(value) {
      this.circleRadio = value ? Number(value.replace(/METER_/, '')) : 0;
    },
    /** @desc 按钮提交 */
    btnSubmit() {
      const formData = this.$refs.formMap.getFormData();

      if (!formData) return;
      console.log('submit============>', formData);
      this.$emit('submit', { ...formData });
      this.btnClose();
    },
    /** @desc 按钮关闭 */
    btnClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style lang="less" scoped>
.fixed-form{
  margin-top: 20px;
}
.margin-top-10{
  margin-top: 10px;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 999;
  background-color: white;
}
</style>
