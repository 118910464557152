var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-form", { attrs: { inline: "" } }),
      _vm.placeList.length
        ? _c(
            "el-form",
            { attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "电子围栏" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.disabled },
                      on: { change: _vm.radioChange },
                      model: {
                        value: _vm.electronFence,
                        callback: function ($$v) {
                          _vm.electronFence = $$v
                        },
                        expression: "electronFence",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "NONE" } }, [
                        _vm._v("无电子围栏"),
                      ]),
                      _c("el-radio", { attrs: { label: "OUT_SIGN_EX" } }, [
                        _vm._v("允许范围外打卡，地点记录为异常"),
                      ]),
                      _c("el-radio", { attrs: { label: "OUT_SIGN_OK" } }, [
                        _vm._v("允许范围外打卡，地点记录为正常"),
                      ]),
                      _c("el-radio", { attrs: { label: "NO_OUT_SIGN" } }, [
                        _vm._v("不允许范围外打卡"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("MapByAttendance", {
        attrs: { visible: _vm.visible },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          submit: _vm.btnSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }