<template>
<div>
  <div>
    <span>（可添加多个打卡时间段）</span>
    <el-button v-if="!disabled" type="text" icon="el-icon-circle-plus-outline" @click="btnAdd">添加</el-button>
  </div>
  <div class="clock-item" v-for="(item,index) in timeList" :key="index">
    <div>
      <el-row type="flex" align="middle">
        <el-time-picker v-model="item.gotoTime"
                        @change="timePickerChange"
                        placeholder="请选择上班时间"
                        value-format="HH:mm:ss"
                        clearable
                        :disabled='disabled'>
        </el-time-picker>
        <span>---</span>
        <el-time-picker v-model="item.gooffTime"
                        @change="timePickerChange"
                        placeholder="请选择下班时间"
                        value-format="HH:mm:ss"
                        clearable
                        :disabled='disabled'>
        </el-time-picker>
      </el-row>
      <el-row type="flex" class="margin-top">
        <span class="margin-right">打卡时间限制</span>
        <div>
          <el-row type="flex" align="middle">
            <el-time-picker
              is-range
              v-model="item.gotoLimitRanage"
              @change="timePickerChange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm:ss"
              :disabled='disabled'>
            </el-time-picker>
            <span class="margin-left">可打上班卡</span>
          </el-row>
          <el-row type="flex" align="middle" class="margin-top">
            <el-time-picker
              is-range
              v-model="item.gooffLimitRanage"
              @change="timePickerChange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="HH:mm:ss"
              :disabled='disabled'>
            </el-time-picker>
            <span class="margin-left">可打下班卡</span>
          </el-row>
        </div>
      </el-row>
    </div>
    <div @click="btnRemove(index)" v-if="!disabled">
      <i class="el-icon-delete icon-delete">删除</i>
    </div>
  </div>
</div>
</template>

<script>
export default {
  enName: '打卡时间',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(curVal) {
        console.log('clock_in_time监听改变事件========', curVal);
        if (curVal && Array.isArray(curVal) && curVal.length > 0) {
          this.timeList = curVal.map((item) => {
            let gotoLimitRanage = ''; // 这里必须设置为空字符串,时间段的选择器不能使用空数组
            let gooffLimitRanage = '';// 这里必须设置为空字符串,时间段的选择器不能使用空数组
            if (item.gotoStartTime && item.gotoEndTime) {
              gotoLimitRanage = [item.gotoStartTime, item.gotoEndTime];
            }
            if (item.gooffStartTime && item.gooffEndTime) {
              gooffLimitRanage = [item.gooffStartTime, item.gooffEndTime];
            }
            return {
              ...item,
              gotoLimitRanage,
              gooffLimitRanage,
            };
          });
        }
      },
    },
  },
  data() {
    return {
      timeList: [],
    };
  },
  methods: {
    /** @desc 添加事件 */
    btnAdd() {
      const item = {
        gotoTime: '', // 上班打卡时间
        gooffTime: '', // 下班打卡时间
        gotoStartTime: '', // 上班打卡限制-开始时间
        gotoEndTime: '', // 上班打卡限制-结束时间
        gooffStartTime: '', // 下班打卡限制-开始时间
        gooffEndTime: '', // 下班打卡限制-结束时间
        gotoLimitRanage: '', // 自定义的字段
        gooffLimitRanage: '', // 自定义的字段
      };
      this.timeList = this.timeList.concat([item]);
    },
    /** @desc 删除 */
    btnRemove(index) {
      this.timeList.splice(index, 1);
      this.emitChange();
    },
    /** @desc 时间选择器改变事件 */
    timePickerChange() {
      this.emitChange();
    },
    /** @desc 上传数据 */
    emitChange() {
      const timeList = this.timeList.map((item) => {
        let rowData = item;
        if (rowData.gotoLimitRanage && rowData.gotoLimitRanage.length > 0) {
          rowData = {
            ...rowData,
            gotoStartTime: rowData.gotoLimitRanage[0],
            gotoEndTime: rowData.gotoLimitRanage[1],
          };
        }
        if (rowData.gooffLimitRanage && rowData.gooffLimitRanage.length > 0) {
          rowData = {
            ...rowData,
            gooffStartTime: rowData.gooffLimitRanage[0],
            gooffEndTime: rowData.gooffLimitRanage[1],
          };
        }
        return rowData;
      });
      this.$emit('input', timeList);
    },
  },
};
</script>

<style lang="less" scoped>
.clock-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  border: 1px solid #ededed;
  margin-bottom: 12px;
  padding: 20px;
  border-radius: 4px;
}
.icon-delete{
  color: red;
}
.margin-left{
 margin-left: 8px;
}
.margin-right{
 margin-right: 6px;
}
.margin-top{
 margin-top: 8px;
}
</style>
